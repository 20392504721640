import { Box, Button, Tab, Tabs } from "@mui/material";
import { homeStyles } from "./pageStyles";
import { useEffect, useState } from "react";
import { getTenants } from "../apiHelper";
import AccountComponent from "../components/AccountComponent";
import AccountHeaderComponent from "../components/AccountHeaderComponent";
import ProvisioningForm from "../components/ProvisioningForm";

const Home = () => {
  const classes = homeStyles();
  const [tenants, setTenants] = useState([]);
  const [tab, setTab] = useState(0);
  useEffect(() => {
    if (tab === 0) {
      getTenants().then(tenants => {
        setTenants(tenants.data);
      });
    }
  }, [tab]);
  return (
    <Box className={classes.outerContainer}>
      <Box>
        <Tabs
          onChange={(e, nv) => {
            setTab(nv);
          }}
          value={tab}
        >
          <Tab label={"Accounts"} />
          <Tab label={"Provision"} />
        </Tabs>
      </Box>
      {tab === 0 ? (
        <>
          <AccountHeaderComponent />
          {tenants.map((t, i) => (
            <AccountComponent key={i} account={t} />
          ))}
        </>
      ) : null}
      {tab === 1 ? (
        <>
          <ProvisioningForm />
        </>
      ) : null}
    </Box>
  );
};

export default Home;
