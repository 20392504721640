import { Alert, Box, Typography } from "@mui/material";
import { accountStyles } from "./pageStyles";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getTenantDetails } from "../apiHelper";
import PersonComponent from "../components/PersonComponent";

const Account = () => {
  const classes = accountStyles();
  const location = useLocation();
  const [accountData, setAccountData] = useState({});
  const [provisionMessage, setProvisionMessage] = useState(null);
  useEffect(() => {
    getTenantDetails({ accountId: location.state.accountId }).then(data =>
      setAccountData(data)
    );
  }, [location.state.accountId]);
  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.title}>
        <Typography variant="h1">
          {accountData.data && accountData.data.accountName}
        </Typography>
      </Box>
      {provisionMessage && (
        <Box className={classes.messageContainer}>
          <Alert
            severity={
              provisionMessage.includes("Email sent succesfully to")
                ? "success"
                : "error"
            }
          >
            {provisionMessage}
          </Alert>
        </Box>
      )}
      <Box className={classes.users}>
        {accountData.users
          ? accountData.users.map((person, i) => (
              <PersonComponent
                person={person}
                key={i}
                setProvisionMessage={setProvisionMessage}
              />
            ))
          : null}
      </Box>
    </Box>
  );
};

export default Account;
