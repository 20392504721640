import { Box, Typography } from "@mui/material";
import { accountComponentStyles } from "./componentStyles";
import { useNavigate } from "react-router-dom";

const AccountComponent = props => {
  const classes = accountComponentStyles();
  const { account } = props;
  const navigation = useNavigate();

  return (
    <Box
      className={classes.outerContainer}
      onClick={() =>
        navigation(`${account._id}`, { state: { accountId: account._id } })
      }
    >
      <Box className={classes.item}>
        <Typography>{account.accountName}</Typography>
      </Box>
      <Box className={classes.item}>
        <Typography>{account.name}</Typography>
      </Box>
    </Box>
  );
};

export default AccountComponent;
