import { makeStyles } from "@mui/styles";

export const accountComponentStyles = makeStyles({
  outerContainer: {
    cursor: "pointer",
    margin: "0.8rem",
    padding: " 0.8rem",
    display: "flex",
    "& .MuiTypography-body1": {
      fontSize: "1rem",
      color: "#3d3d3d",
      fontWeight: "500"
    }
  },
  item: {
    display: "flex",
    justifyContent: "flex-start",
    width: 250
  }
});

export const accountHeaderComponentStyles = makeStyles({
  outerContainer: {
    margin: "0.8rem",
    padding: " 0.8rem",
    paddingBottom: 0,
    display: "flex",
    "& .MuiTypography-h3": {
      fontSize: "0.8rem",
      textTransform: "uppercase",
      color: "#9e9e9e",
      fontWeight: "500"
    }
  },
  item: {
    display: "flex",
    justifyContent: "flex-start",
    width: 250
  }
});
export const provisioningFormStyles = makeStyles({
  outerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  innerContainer: {
    width: 350,
    display: "flex",
    flexDirection: "column",
    "& .MuiTypography-h3": {
      fontSize: "0.8rem",
      textTransform: "uppercase",
      color: "#6a6a6a",
      fontWeight: "500"
    }
  },
  fieldContainer: {
    padding: "1rem"
  },
  selectContainer: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
  },
  optionItemContainer: { width: "100%" },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center"
  }
});

export const personComponentStyles = makeStyles({
  outer: {
    zIndex: 999,
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    display: "flex",
    marginTop: "0.5rem",
    backgroundColor: "white",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    },
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 300
    },
    justifyContent: "space-between"
    // "@media (max-width: 1100px)": {
    //   flexDirection: "column"
    // }
  },
  left: {
    display: "flex",
    alignItems: "center"
  },
  right: {
    display: "flex",
    alignItems: "center"
  },
  statisticsButton: {
    width: 100,
    marginLeft: "1rem",
    marginRight: "1rem",
    padding: "0.8rem",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    borderRadius: 18
  },
  lowParticipation: {
    backgroundColor: "#ffcaca"
  },
  highParticipation: {
    backgroundColor: "#c3dec2"
  },
  name: {
    marginLeft: "0.8rem !important",
    marginRight: "0.8rem !important"
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
    width: 300
  },
  hamburgerMenuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiTypography-body2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,

      color: "#474747"
    }
  },
  deactivateModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 310,
    backgroundColor: "white",
    padding: "1.5rem",
    paddingTop: "1.2rem",
    paddingBottom: "1.2rem",
    borderRadius: 24,
    boxShadow: 24
  },
  modalInner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
    // alignItems: "center"
  },
  modalButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%"
  }
});
