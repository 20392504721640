import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:9090/";

export const getTenants = async data => {
  const url = `${apiUrl}tenants`;
  const config = {
    params: { ...data }
  };
  const res = await axios.get(url, config);
  return res.data;
};

export const getTenantDetails = async data => {
  const url = `${apiUrl}tenants/details`;
  const config = {
    params: { ...data }
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const checkSalespersonUser = async (token, data) => {
  const url = `${apiUrl}tenants/salesperson`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data }
  };
  const res = await axios.get(url, config);
  return res.data;
};

export const updateQuestion = async (token, questionId, data) => {
  const url = `${apiUrl}questions/update-one`;
  //TODO: AUTH
  // const config = {
  //   headers: { Authorization: `Bearer ${token}` }
  // };
  const res = await axios.put(
    url,
    {
      questionId,
      question: data.question,
      type: data.type,
      active: data.active,
      day: data.day,
      requestingUserId: data.requestingUserId
    }
    // config
  );
  return res.data;
};

export const provisionTenant = async data => {
  const url = `${apiUrl}tenants`;
  const config = {
    // headers: { Authorization: `Bearer ${token}` }
  };
  const res = await axios.post(url, { ...data }, config);
  return res.data;
};

export const triggerUpdateEmail = async data => {
  const url = `${apiUrl}tenants/trigger-password-reset`;
  const config = {
    // headers: { Authorization: `Bearer ${token}` }
  };
  const res = await axios.post(url, { ...data }, config);
  return res.data;
};
