import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import { Auth0ProviderWithHistory } from "./auth0ProviderWithHistory";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: ["League Spartan", "regular"].join(",")
  },
  palette: {
    primary: {
      light: "#456A44",
      main: "#456A44",
      dark: "#456A44",
      contrastText: "#fff"
    },
    secondary: {
      main: "#494949"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
