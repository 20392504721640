import { makeStyles } from "@mui/styles";

export const homeStyles = makeStyles({
  outerContainer: {
    height: "100vh"
  }
});
export const accountStyles = makeStyles({
  outerContainer: {
    height: "100vh"
  },
  title: {
    padding: "2rem",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem"
    }
  },
  users: {
    padding: "2rem"
  }
});
