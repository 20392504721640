import { Avatar, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { personComponentStyles } from "./componentStyles";
import { triggerUpdateEmail } from "../apiHelper";

const PersonComponent = (props) => {
  const classes = personComponentStyles();
  const { person, setProvisionMessage } = props;
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <Box className={classes.outer}>
      <Box className={classes.left}>
        <Box className={classes.nameContainer}>
          <Avatar></Avatar>
          <Typography className={classes.name}>{person.name}</Typography>
        </Box>
        <Box className={`${classes.statisticsButton}`}>
          <Typography>{person.email}</Typography>
        </Box>
      </Box>
      <Box className={classes.right}>
        <MoreHorizIcon
          style={{ color: "#747474", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          sx={{ padding: 0 }}
        >
          <MenuItem
            onClick={() =>
              triggerUpdateEmail({ email: person.email })
                .then(() => {
                  setProvisionMessage(
                    `Email sent succesfully to ${person.email}`
                  );
                  setTimeout(() => setProvisionMessage(null), 2000);
                })
                .catch(() => {
                  setProvisionMessage("Failed, please try again");
                  setTimeout(() => setProvisionMessage(null), 2000);
                })
            }
          >
            <Box className={classes.hamburgerMenuItem}>
              <EditIcon
                fontSize="inherit"
                sx={{ paddingBottom: 0.4, marginRight: 1 }}
              />
              <Typography variant="body2">Resend Password Email</Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default PersonComponent;
