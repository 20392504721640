import { Box, Typography } from "@mui/material";
import { accountHeaderComponentStyles } from "./componentStyles";

const AccountHeaderComponent = props => {
  const classes = accountHeaderComponentStyles();
  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.item}>
        <Typography variant="h3">Account Name</Typography>
      </Box>
      <Box className={classes.item}>
        <Typography variant="h3">Database Name</Typography>
      </Box>
    </Box>
  );
};

export default AccountHeaderComponent;
