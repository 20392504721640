import { Box } from "@mui/material";
import "./App.css";
import Home from "./pages/Home";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { checkSalespersonUser } from "./apiHelper";
import Account from "./pages/Account";
import { Route, Routes } from "react-router-dom";

function App() {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
    user,
    logout,
  } = useAuth0();
  const [isSalesperson, setIsSalesperson] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (user) {
      getAccessTokenSilently().then((t) => {
        checkSalespersonUser(t, { externalId: user.sub }).then((data) => {
          setIsSalesperson(data.salesperson);
          setLoaded(true);
        });
      });
    }
  }, [user]);
  if (loaded && !isSalesperson) {
    logout({
      logoutParams: {
        returnTo:
          process.env.REACT_APP_REDIRECT_URL || "http://localhost:3001/",
      },
    });
  }
  if (!isLoading) {
    if (isAuthenticated) {
      return (
        <Routes>
          <Route index element={<Home />} />
          <Route path="/:accountId" element={<Account />} />
        </Routes>
      );
    } else {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }
}

export default App;
